<script setup>
import VStatusInfo from '@/components/statusElements/VStatusInfo'
import VFileList from '@/components/list/FileList/VFileList'
import VBestOfferList from '@/components/list/BestOfferList/VBestOfferList'
import VTable from '@/components/ui/table/VTable'
import VTextBlock from '@/components/textblock/VTextBlock'
import { onMounted, reactive, ref } from 'vue'
import TradeHistoryOffer from '@/components/views/Trade/TradeHistoryOffer'
import { getProtocolById } from '@/http/offer/offerApi'
import { useRoute } from 'vue-router'
import { copyObject } from '@/utils/utils'
import VSpinnerLoader from '@/components/views/Spinner/VSpinnerLoader'
import { formatCurrency } from '@/utils/format/number'

const protocol = reactive({})
const winnersList = reactive([])
const file = reactive([])
const rows = reactive([])
const route = useRoute()
const isLoading = ref(true)
const URL_HISTROTY_OFFER = ref('')
const ACTION_HISTORY_OFFER = ref('')

function pushStatusInfo (lot) {
  const status = [
    { title: 'Дата начала и время публикации', description: lot.published_at },
    { title: 'Дата и время начала ТП', description: lot.started_at },
    { title: 'Дата и время окончания ТП', description: lot.duration },
    { title: 'Статус', description: lot?.status?.name }
  ]
  statusInfo.push(...status)
}
function pushFiles (lot) {
  if (lot.file?.length) {
    file.push(...protocol.lot.file)
  }

  if (lot.protocol?.length) {
    file.push(...protocol.lot.protocol)
  }
}

function setWinnerList (protocol) {
  const item = {
    ...protocol.offers[0],
    user: protocol.user,
    offers: protocol.offers
  }
  winnersList.push(item)
}

function setRows (protocol) {
  let added = ''
  protocol.offers.forEach(offer => {
    added = ''
    offer.answers.position.parameters.forEach(parameter => {
      added += ' ' + parameter.name
    })

    const counter = offer.answers.position.quantity
    const sum = formatCurrency(+counter * +offer.price)

    const item = {
      price: formatCurrency(offer.price),
      sum,
      counter,
      name: offer.answers.position.name,
      added
    }
    rows.push(item)
  })
}

async function getProtocolDetail () {
  try {
    const { data } = await getProtocolById(route.params.id)
    copyObject(protocol, data)
    pushStatusInfo(protocol.lot)
    pushFiles(protocol.lot)
    setWinnerList(protocol)
    setRows(protocol)
    URL_HISTROTY_OFFER.value = `/trade-offer/lots/${protocol.lot.id}/before-result`
  } finally {
    isLoading.value = false
  }
}

onMounted(getProtocolDetail)

const mainInfo = reactive({
  place: 'tender.sitno.ru'
})

const statusInfo = reactive([])
const headerRow = [
  {
    name: 'Наименование позиции',
    value: 'name'
  },
  {
    name: 'Кол-во',
    value: 'counter'
  },
  {
    name: 'Цена за ед',
    value: 'price'
  },
  {
    name: 'Сумма с НДС',
    value: 'sum'
  }
]

const printPdf = () => {
  const test = document.querySelector('#protocol').innerHTML
  const originalContents = document.body.innerHTML

  document.body.innerHTML = test
  window.print()

  document.body.innerHTML = originalContents
}
</script>

<template>
  <div
    v-if="isLoading"
    class="width-100 text-center"
  >
    <VSpinnerLoader />
  </div>
  <div
    v-else
    id="protocol"
  >
    <div class="block__subtitle mb-40">
      Протокол {{ protocol.id }}
    </div>

    <button class="btn btn-primary btn-bg-green mb-40" @click="printPdf">Печать pdf</button>

    <div class="between mb-20 p-30 bg--light-gray">
      <div>
        <div class="block__subtitle mb-20">
          № {{ protocol.lot.id }}
        </div>
        <div class="block__subtitle-small mb-20">
          {{ protocol.lot.name }}
        </div>
      </div>
      <VTextBlock
        subtitle="Начальная цена"
        :description="`${formatCurrency(protocol.lot?.start_price)} руб.`"
        font-size-description="24"
      />
    </div>

    <div class="mb-60 p-30 bg--yellow">
      <VStatusInfo
        :info="statusInfo"
        type="bidder"
      />
    </div>

    <div class="block__subtitle-small mb-30">
      Информация
    </div>
    <div class="grid grid--col-2 mb-60 p-30 bg--light-gray">
      <VTextBlock
        subtitle="Место проведения"
        :description="mainInfo.place"
        is-link
      />
      <VTextBlock
        subtitle="Тел. ответственного"
        :description="protocol.user.trusted_person"
        is-tel
      />
      <VTextBlock
        subtitle="Организатор"
        :description="protocol.user.name"
      />
      <VTextBlock
        subtitle="Адрес электронной почты"
        :description="protocol.user.email"
        is-email
      />
      <VTextBlock
        subtitle="Адрес"
        :description="protocol.user.organization_actual_address"
      />
    </div>

    <div class="block__subtitle-small mb-30">
      Документация
    </div>
    <VFileList
      class="mb-60"
      grid
      :files="file"
    />

    <div class="block__subtitle-small mb-30 max-w-400">
      Ваше ценовое предложение
    </div>
    <VBestOfferList
      isBidder
      class="mb-60"
      hide-counter
      :best-offer-list="winnersList"
    />

    <div class="block__subtitle-small mb-30">
      Ценовое предложение
    </div>
    <VTable
      :is-min-height="false"
      :touchBarList="[]"
      :headRow="headerRow"
      :rows="rows"
      :is-checkbox="false"
    />

    <div class="block__subtitle-small mb-30">
      Ставки всех участников
    </div>
    <TradeHistoryOffer
      :url="URL_HISTROTY_OFFER"
      :action="ACTION_HISTORY_OFFER"
    />
  </div>
</template>
